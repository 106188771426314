import axios from 'axios'
import { useState, useMemo } from 'react'
import { Navigate } from 'react-router';
import Menu from '../partils/Menu'

import TenancyColor from '../midllers/TenancyColor';
import { Link } from 'react-router-dom';


export default function Pedidos(){
  const config = useMemo(() => ({
      url:'api/auth/'
  }), []);
  
    const tenancylocal = localStorage.getItem('ClienteTenancy')
    const [sucesse,setSucess]=useState(false)
    const [status, setStatus]=useState("")
    const initialValues={
        email:'',
        password:''
    }
    const [values,setValues]=useState(initialValues);
    
    function onSubmit(ev) {
        ev.preventDefault();
        //console.log("clicado")
        axios.post(config.url, values).then(res=>{
          //console.log(res)
            if(res.data.ERRO==="Nome ou senha incorreto(s)"){
                alert("Usuario ou senha incorretos")
                setStatus("Senha errada")
            }
            else{
                if(res.data.token){
                    localStorage.setItem('keyItemNameg', res.data.token);
                    //localStorage.setItem('keyItemNameg', res.data.token)
                    const convert = JSON.stringify(res.data.a); 
                    localStorage.setItem('ItemUsers',convert )  
                    localStorage.setItem('ClienteTenancy',res.data.a.id_tenancy) 
                    //console.log(res.data.a)
                    localStorage.setItem('localcolor1',res.data.a.color1)
                    setStatus("Carregando...")
                }
                //console.log(res.data.posts)
                setSucess(true)
            }
            console.log(res.data)
        })
    }
   
//pedidoIdListPedidos
const ListPedidos = localStorage.getItem('pedidoIdDataArray')
//console.log(IdListPedidos)
const dataPedidos = JSON.parse(ListPedidos)
const final = dataPedidos?.map((namesString) => (
  <Link to={`CheckPedidoAuth/${namesString.id}`} className='w100 column center h5 ma10b0 pp cont-circ15 borda1' key={namesString.id}>
    <div className='c1'>Pedido {namesString.data.slice(0, 10)}</div>
  </Link>
))
function limparHistorico() {
  if (window.confirm('Tem certeza de que deseja excluir o histórico?')) {
    localStorage.removeItem('pedidoIdDataArray');
    window.location.reload()
    // Outras ações que você deseja executar após a remoção do histórico
  }
}


  return(
    <div className='w100 column center b26 h100'>
      {sucesse&&<Navigate to="/painel" />}
      <Menu/>
      <section className='h100 w40 cell-tamanho90 column around'>
        
        <div className='w100 h10 row between'>
          <h5 className='w100'>Pedidos</h5>
          <h5 className='fb cont-circ15 h5 borda1 w33 column center'><Link to="/carrinho">Carrinho</Link></h5>
          <h5 className='fb cont-circ15 h5 b3 w33 column center'><Link to="/pedido">Pedidos</Link></h5>
        </div>
        
          <div className='h40 w100 cont-circ8  column center h80'>
            <div className="w100 column h80">
            {ListPedidos?
              <label className='pcb10'>Clique nos botões abaixo para acompanhar os pedidos!</label>
              :
              <div className='w100 h80 column center'>
                <div className="b16 h20 w100 cont-circ8 ma10b0 column center">
                Sem pedidos concluídos
                </div>
              </div>
            }
            {final}
            <div className='pcb20' onClick={limparHistorico}>Limpar histórico</div>

            </div>
          </div>
          <label className='fs08em ma10b0 w100 cen'>{tenancylocal} - Delivery</label> 
      </section>
    </div>
  )
}

