import { useEffect, useState } from 'react'
import axios from 'axios'
//COMPONENTES PARTES

//INPORTAÇOES DE ICONES E IMAGENS
import IconChecked from '../../acets/img_icon/checked.svg'
import IconLixo from '../../acets/img_icon/lixo.svg'

import MenuPainelAdmin from '../partils/AdminMenuPainel'
import IconNoChecked from '../../acets/img_icon/nochecked.svg'
//import IconEdit from '../../acets/img_icon/edit.svg'

import AdminItensPedido from '../partils/AdminItensPedido'
import IcoStatus from '../partils/IcoStatus'

import IconSaberto from '../../acets/img_icon/ico_s_aberto.svg'
import IconScancel from '../../acets/img_icon/ico_s_cancel.svg'
import IconSfinalizado from '../../acets/img_icon/ico_s_finalizado.svg'
import IconSprod from '../../acets/img_icon/ico_s_prod.svg'
import IconSpromo from '../../acets/img_icon/ico_s_promo.svg'

import TenancyColor from '../midllers/TenancyColor'
import { Link, useNavigate } from 'react-router-dom';
import AdminHeader from '../partils/AdminHeader'

export default function AdminOrcamento(){
     const navigate = useNavigate();
    //CONGIGURAÇÕES=============================
    const config={
        pageName:'Orçamentos',
        mainstring:'nome',
        url:'/api/orcamento/',
        url2:'',
        search:'/api/orcamento/search/',
        urlOptions1:'/api/entregador',
        params:'',
        messegerError:'ATENÇÃO - Preencha os campos para salvar os dados ;D',
    }
     //INICIANDO CICLO DE ESTADOS
     useEffect(()=>{
        rec();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
    //STATES======================================
    //id_pedido	status	forma_de_pagamento	troco	observacoes	cliente	createdAt	updatedAt
    const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
        status: '',	forma_de_pagamento: '',	troco: '',bairro:'0',rua:'0',numero:'0',complemento:'0',observacoes:'null',	cliente: '', nome:''
    }
    const [openNew,setOpenNew]=useState(false)// ABRIR TELA DE CADASTRAR E EDITAR
    const [openDetaisStatusPedido,setOpenDetaisStatusPedido]=useState()// ABRIR TELA DE CADASTRAR E EDITAR
    const [values, setValues]=useState(initialValues) // INICIANDO UM STATE
    const [data, setData] = useState([])
    const [showfilters, setShowfilters]=useState(false)
    
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
    const day = String(today.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
    const formattedToday = `${year}-${month}-${Number(day) + 1}`;
   
    const [intervalfinalDate, setIntervalfinalDate] = useState(formattedToday);
    const [intervalinitialDate,setIntervalinitialDate]=useState('2024-02-01')
    //const [idparamsPedido, setIdparamsPedido] = useState()
    const tlocal = localStorage.getItem('keyItemNameg')
    const headers = {
        'Content-Type': 'application/json',
        'token':tlocal,
    };
    //const [options1,setOptions1] = useState([])
    //FUNÇÕES=====================================
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
        axios.get(`/api/orcamento/${intervalinitialDate}/${intervalfinalDate}`, { headers }).then(resp => {
            const invalid = {ERRO: 'token invalido 3'};
                if (JSON.stringify(resp.data[0]) === JSON.stringify(invalid)) {
                  navigate('/login');
                  window.location.reload(true);
                }
                else{
                  setData(resp.data);
                }
                //console.log(data)
        })
        //console.log(data)
            
    }
    function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
        const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV
        setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
    }
    function onChangeSelect(ev) {//ALTERAR OS STATES DOS INPUTS
        const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV
        setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
        //console.log(value)
        axios.get(value?'/api/orcamentostatus/'+value:config.url).then(resp=>{
            setData(resp.data)
           // console.log(resp.data)
        })
    }
    function alterar(idpedido,status) {
      axios.put('/api/orcamento/' + idpedido, { 'status': status }).then(resp => {
        rec();
      });
    }
    const exclui = function(id){//EXCLUI
        axios.delete(config.url + id).then(()=>{
            rec();
            setOpenNew(false)
            setValues(initialValues)
        })
    }
    function onSubmitSearch(ev){//PESQUISA
        ev.preventDefault();
        axios.post(config.search, values).then(respp=>{
            setData(respp.data)       
        })
       
    }
    {/*function redirectToWhatsapp(phoneNumber,idorcamento,idcliente) {
        const url = `https://wa.me/${phoneNumber}`;
        window.open(url, '_blank');
    }*/}
    function redirectToWhatsapp(phoneNumber, idorcamento, idcliente) {
        let message = '';
    
        if (idorcamento && idcliente) {
            const data = idcliente;
            const apenasNumeros = data.replace(/\D/g, ""); // Esta expressão regular substitui todos os caracteres não numéricos por uma string vazia
            //console.log(apenasNumeros); // Saída: "20240416201042000"

            message = `Segue o seu orçamento no link: https://autometricas.com.br/areadocliente/orcamentoAuth0/${idorcamento}/${apenasNumeros}`;
        } else {
            message = 'Olá! Como posso ajudá-lo?';
        }
    
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
    }
    //console.log(data)

    const final =data.map(//MAPEIA TODO O FILTRO
        
    namesString =>{
        const date = new Date(namesString.createdAt);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear().toString()} às ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
        return (
            <div className='w100 h4 column center ma5 sombra-small cont-circ7 b2' 
              key={namesString.id_orcamento_venda }
              style={{
                            borderLeft: `4px solid ${
                            namesString.status === 1
                                ? "#68A652"
                                : namesString.status === 2
                                ? "#FFEC87"
                                : namesString.status === 3
                                ? "#B1FF95"
                                : namesString.status === 4
                                ? "#FFA487"
                                : "transparent"
                            }`
                        }}
                    >
                <div className='w90 column center ma5'>
                    <div className='w100 row between '>
                       { /*<div className='w10'>
                            <IcoStatus props={namesString.status}/>
                          </div>*/}
                       
                       <div
                        className='w50 fw700 fs1p3em ma5 c11'
                       
                        >
                        {namesString.cliente.nome}
                        </div>

                        {//onClick={()=>alterar(namesString.id_pedido)}

                        /*
                         <b> <img src={IconLixo} onClick={()=>exclui(namesString.id_pedido)} alt="iconlixo"></img> </b>
                        
id_orcamento_venda

                        */ 
                        <div className="w40 h4 row end ">
                            <div className='w100 row end'>
                                {/*<div className='c1 linha cell-none pp ml10'>Print</div>*/}
                                <div onClick={()=>setOpenDetaisStatusPedido(namesString.id_orcamento_venda )||openDetaisStatusPedido===namesString.id_orcamento_venda &&setOpenDetaisStatusPedido(false) } className='linha cell-texto-p  pp  cell-tamanho-metade50 cen ml10 cell-m0 c12'>Status</div>
                                <Link to={`/adminvendarapidaEdtar/${namesString.id_orcamento_venda}`} className='cell-tamanho-metade50 '>
                                    <div className=' linha cell-texto-p pp cell-tamanho cen c12'>Editar</div>
                                </Link>
                            </div>
                            {openDetaisStatusPedido===namesString.id_orcamento_venda &&
                            <div className='w100 h100 posifixedinicio fundo-opaco left0 column center z17' onClick={()=>setOpenDetaisStatusPedido(false)}>
                                <div className='w40 h40 b2 cell-tamanho90 cont-circ8 column center'>
                                    <div className='w90'>
                                        <h5 className=''>Alterar Status</h5>
                                        <label className='w100'>Cliente:</label>
                                        <strong>{namesString.cliente.nome}</strong>
                                        <h6 className='w100'>Selecione o status:</h6>
                                        <div className='w100 linha ma10b0 pp pcb10 row pp' onClick={()=>alterar(namesString.id_orcamento_venda,1)}>
                                            <img src={IconSaberto} alt="fff" className='mac0l8'/>
                                            <label className='pp'>Aguardando peças/retifica</label>
                                        </div>
                                        <div className='w100 linha ma10b0 pp pcb10 row pp' onClick={()=>alterar(namesString.id_orcamento_venda,2)}>
                                            <img src={IconSprod} alt="fff" className='mac0l8'/>
                                            <label className='pp'>Finalizado pagamento em aberto</label>
                                        </div>
                                        <div className='w100 linha ma10b0 pp pcb10 row pp' onClick={()=>alterar(namesString.id_orcamento_venda,3)}>
                                             <img src={IconSfinalizado} alt="fff" className='mac0l8'/>
                                            <label className='pp'>Finalizado Pago</label>
                                        </div>
                                        <div className='w100 linha ma10b0 pp pcb10 row pp' onClick={()=>alterar(namesString.id_orcamento_venda,4)}>
                                            <img src={IconScancel} alt="fff" className='mac0l8'/>
                                            <label className='pp'>Cancelado</label>
                                        </div>
                                        <div className='w100 linha ma10b0 pp pcb10 row pp' onClick={()=>exclui(namesString.id_orcamento_venda)}>
                                             <img src={IconScancel} alt="fff" className='mac0l8'/>
                                            <label className='pp'>Excluir</label>
                                            <label className='fs08em p10'>Para excluir o pedido exclua todos os itens do pedido. Esse é um recurso permanente e não poderá ser revertido.</label>
                                        </div>  
                                        <label className='fs08em'> Duvidas sobre o Carro?</label>
                                        <div className='w100 linha ma10b0 pp pcb10 row pp' onClick={() => redirectToWhatsapp(namesString.cliente.telefone)}>
                                            <label className='w100 c1 pp'>Contato via WhatsApp</label>
                                        </div>
                                        <button className='w100 ma10b0 row'
                                        style={{backgroundColor: TenancyColor().backgroundColor}}
                                        onClick={() => redirectToWhatsapp(namesString.cliente.telefone,namesString.id_orcamento_venda,namesString.createdAt)}>
                                            <label className='w100 c3 pp'>Enviar Orçamento via WhatsApp </label>
                                        </button>
                                        <div className='row between ma25b0'>
                                            <label>Sem mais opções</label>
                                            <button className='btn05' onClick={()=>setOpenDetaisStatusPedido(false)}>Cancelar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                        }
                    </div>
                    
                    <div className='w100  row between'>
                        <div className='row w100 between'>
                            <label className='fw600 c11'>{namesString.cliente.carro} {namesString.cliente.placa&&'/ '+namesString.cliente.placa }</label>
                            <div className='row center pcb7'>
                                <label className='fs08em c12'>Data: {formattedDate}</label>
                            </div>
                        </div>
                      
                        
                    </div>
                    <div className='w100 w100 column linha'>
                        <div className='w100 row between '>
                            {namesString.cliente.telefone&&
                            <div className='row between'>
                               <label className='fb fs08em c11'>Tell: </label>
                                <label onClick={() => redirectToWhatsapp(namesString.cliente.telefone)} className='c11'> {namesString.cliente.telefone}</label>
                            </div>}

                               
                        </div>
                    
                       
                        
                        
                    </div>
                
                    <div className='w100'>
                        <AdminItensPedido id={namesString.id_orcamento_venda}/>
                    </div>
                </div>
            </div>);
})

   


    return(//id_pedido	status	forma_de_pagamento	troco	observacoes	cliente	createdAt	updatedAt
        <>
       <section className='h100 row between b31'>
           
            <MenuPainelAdmin/>
            <div className="h100 w80 cell-tamanho row center celular">
                    
                    <div className="w90 row between cell-tamanho celular">
                        <div className="h100 w100 cont-circ3  column cell-tamanho cell-paddingcb10">
                        <h5 className='w90'>Pesquisar {config.pageName}</h5>
                        <form className=' w90 row center' onSubmit={onSubmitSearch} autoComplete='off'>
                            <div className='w80'>
                                <input type="text" name="nome" onChange={onChange} placeholder='Digite o nome do cliente'/>
                            </div>
                            <div className='w20'>
                                <input type="submit" value="Pesquisar" style={{backgroundColor: TenancyColor().backgroundColor}} className='cell-text-smile08'/>
                            </div>
                        </form>
                        <div className='w90 h20 column'>
                            
                            <div className='row center w100 h5'>
                                
                                {showfilters&&
                                    <div className="w100 b2 c3 sombra-small flex row center ma10b0">
                                        <label className='w95 ma5'>Selecionar filtro</label>
                                        <select name="status" onChange={onChangeSelect} className='w95'>
                                            <option value=''>Todos</option>
                                            <option value="1"> Aguardando peças/retifica</option>
                                            <option value="2"> Finalizado pagamento em aberto</option>
                                            <option value="3"> Finalizado Pago</option>
                                            <option value="4"> Cancelado</option>
                                        </select>
                                        <label className="w95 ma5">Selecione o intervalo de datas para obter os dados</label> 
                                        <div className="w95 row between">
                                        <div className="w33">
                                            <label className="fs08em">Início</label>
                                            <input 
                                                type="date"
                                                onChange={(e) => setIntervalinitialDate(e.target.value)}
                                            />
                                        </div>
                                        <div className="w33">
                                            <label className="fs08em">Fim</label>
                                            <input 
                                                type="date"
                                                onChange={(e) => setIntervalfinalDate(e.target.value)}
                                            />
                                        </div>
                                        <div className="w25">
                                            <label>Ação</label>
                                            <button className="w95" onClick={()=>rec()}>ir</button>
                                        </div>
                                        <label className="fs08em ma5">
                                            {"De: "+intervalinitialDate + " até " + intervalfinalDate} 
                                        </label>
                                        </div>
                                        
                                    </div>
                                    }
                                     <label 
                                        className="w100 c1 cen fs08em pp p5"
                                        onClick={()=>setShowfilters(!showfilters)}>
                                        {showfilters?'Fechar Filtro':'Abrir Filtro'}
                                    </label>
                            </div>
                                {//<label className='w100 ma5 fs1p3em'>{config.pageName} Listados(a)</label>
                                }
                            {final}
                        </div>     
                    </div>
                </div>
            </div>
        
        </section>
      </>
    )
}




