import IcoAlert from '../../acets/img_icon/icoAlert.svg'
import icoConfg from '../../acets/img_icon/icoConfg.svg'
import icoMensage from '../../acets/img_icon/icoMensage.svg'
import { Link } from "react-router-dom";

export default function AdminHeader(props){
  const tenancylocal = localStorage.getItem('ClienteTenancy')
    return(
      <>
            <div className="w100 linham2 row center b2">
               <div className="w90 row between">
                  <div className='b'></div>
                  <h5 className="c1 w25 cell-tamanho-metade">{props.props}</h5>
                  <div className="w33 h5 row between cell-tamanho-metade50">
                     <div className="row w40 around cell-tamanho">
                        <img src={IcoAlert} className="pp" onClick={()=>alert('Sem notificações')}/>
                        <Link to="/admindadosempresa"><img src={icoConfg} className="pp"/></Link>
                        <img src={icoMensage} className="pp" onClick={()=>alert('Sem Mensagens')}/>
                     </div>
                     <div className="row end w50 cell-none">
                        <div className="bola b3 w14 h4 cont-circ column center ml10">U</div>
                        <label className="c1 fs08em">{tenancylocal}</label>
                     </div>
                  </div>
               </div>
            </div>
            
           
        </>
    )
}