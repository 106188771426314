import React from "react";
import { Chart } from "react-google-charts";

function ChartFormaPG({ pg }) {
  // Formata os dados para o gráfico de pizza
  const data = [
    ["Forma de Pagamento", "Quantidade"],
    ...pg.map(item => [item.forma_pgto, item.quantidade]),
  ];

  // Definindo as opções do gráfico com cores personalizadas
  const options = {
    colors: ['#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087'], // Tons de azul e roxo
    legend: { position: 'bottom' },
  };

  return (
    <div className="w100 column center">
      <div className="w90">
        <h5>Formas de pagamento</h5>
      </div>
      <Chart
        chartType="PieChart"
        data={data}
        options={options}
        width="100%"
        height="300px"
        legendToggle
      />
    </div>
  );
}

export default ChartFormaPG;
