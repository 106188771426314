import { useEffect, useState } from 'react'
import axios from 'axios'
//COMPONENTES PARTES


//INPORTAÇOES DE ICONES E IMAGENS
import IconChecked from '../../acets/img_icon/checked.svg'


import MenuPainelAdmin from '../partils/AdminMenuPainel'
import IconNoChecked from '../../acets/img_icon/nochecked.svg'
import IconEdit from '../../acets/img_icon/edit.svg'

import TenancyColor from '../midllers/TenancyColor'
import AdminHeader from '../partils/AdminHeader'

export default function AdminProdutoCategoria(){
    //CONGIGURAÇÕES=============================
    const config={
        pageName:'Categoria',
        mainstring:'produtocategoria',
        url:'555',
        url2:'555',
        search:'66',
        urlOptions1:'66',
        urlOptions2:'66',
        params:'',
        messegerError:'ATENÇÃO - Preencha os campos para salvar os dados ;D',
    }
     //INICIANDO CICLO DE ESTADOS
     useEffect(()=>{
        rec();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
    //STATES======================================
    //id_produto_categoria	nome_categoria status	createdAt	updatedAt
    const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
        nome_categoria: '',status:1,id_imagem:''
    }
    const [openNew,setOpenNew]=useState(false)// ABRIR TELA DE CADASTRAR E EDITAR
    const [values, setValues]=useState(initialValues) // INICIANDO UM STATE
    const [data, setData] = useState([])
    const[dataInputImagem,setDataInputImagem]=useState([])
    //FUNÇÕES=====================================
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
        axios.get(config.url).then(resp=>{
            setData(resp.data)})
        axios.get(config.urlOptions2).then(resp=>{
            setDataInputImagem(resp.data)
        })
    }
    function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
        const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV
        setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
    }
    function onSubmit(ev){//CADASTRAR NO BANCO DE DADOS, obs refatorado para tirar o if e else e usar o. ternario edit e cadastrar
        ev.preventDefault();
            axios[values.id_produto_categoria?'put':'post'](config.url,values).then(()=>{
                setOpenNew(false)
                setValues(initialValues);
                rec();
            })
    }
    const editar = function(id){//
        setOpenNew(true)
        axios.get(config.url + id).then(respp=>{
            setValues(respp.data[0])
            })
    }
    const exclui = function(id){
        axios.delete(config.url + id).then(()=>{
            rec();
            setOpenNew(false)
            setValues(initialValues)
        })
    }
    function onSubmitSearch(ev){//PESQUISA
        ev.preventDefault();
        axios.post(config.search, values).then(respp=>{
            setData(respp.data)       
        })
    }
    //COMPONENTES DE LOOP==============================
    const final = data.map(
        namesString =>
            <div className='w100 h4 row ma5 between linha'  key={namesString.id_produto_categoria}> 
                <div className='w7'>{namesString.status===1?<img src={IconChecked}/>:<img src={IconNoChecked}/>}</div>
                <div className='w10 cell-tamanho10'>{namesString.id_produto_categoria}</div>
                <div className='w40 cell-tamanho80 fb cell-tamanho90'>{namesString.nome_categoria}</div>
                
                <div className='w30 fs08em cell-texto-p c4 cell-tamanho90'>{namesString.createdAt}</div>
                <div className="w7 h4 row center pp">
                    <b onClick={()=>editar(namesString.id_produto_categoria)}> <img src={IconEdit}></img> </b>
                </div>
            </div>
    )
    const optionInputImagem = dataInputImagem.map(repsValues=>
        <option key={repsValues.id_imagem} value={repsValues.id_imagem}>{repsValues.nome}</option>
    )
    return(
        <>
        {openNew&&           
            <div className='w100 h100 posifixedinicio column center z4' >
                <div className='w100 h100 posifixedinicio fundo-opaco z5' onClick={()=>(setOpenNew(false) & setValues(initialValues))}>
                </div>
                <div className='h20 w35 b2 cont-circ3 sombra column center z10 cell-tamanho90'>
                    <div className='w90 h20'>
                        <div className='w100'>
                            {values.id_produto_categoria?<h5>Editar {config.pageName}</h5>:<h5>Cadastrar novo(a) {config.pageName}</h5>  }
                        </div>
                        <form onSubmit={onSubmit} autoComplete='off'>
                            <div>
                                <label>Nome Categoria:</label>
                                <input type="text"  name="nome_categoria"  onChange={onChange} value={values.nome_categoria} autoComplete='off'></input>
                            </div>
                            {/*
                            <div>
                                <label>status:</label>
                                <input type="text"  name="status"  onChange={onChange} value={values.status}></input>
                            </div>
                            */}
                            <div>
                                <label>Imagem:</label>
                                <select name="id_imagem" onChange={onChange} >
                                    <option>Selecione</option>
                                    {optionInputImagem}
                                </select>
                            </div>
                            <div className='cell-margin5'>
                            <button type="submit" className='w100'>{values.id_produto_categoria ? 'Atualizar' : 'Cadastrar'}</button>
                            </div>
                        </form>
                        <div className='w100 row between'>
                            {values.id_produto_categoria?<button className='w25 btn04 cell-tamanho-metade' onClick={()=>exclui(values.id_produto_categoria)}>Excluir</button>:<label className='w40'>Sem mais opções</label>}
                            <button onClick={()=>(setOpenNew(false) & setValues(initialValues))} className="w40 ma10b0 btn05">Cancelar</button>
                        </div>
                        
                    </div>
                </div>
            </div>
        }
        
        <section className='h90 column center b15 b30'>
            <AdminHeader props={config.pageName}/>
            <div className="h20 w90 posiabstop row between aligitensstart celular">
                <MenuPainelAdmin/>
                <div className="w70 row between cell-tamanho">
                    <div className="h20 w100 b2 cont-circ3 sombra column center">
                        <h5 className='w90'>Pesquisar {config.pageName}</h5>
                        <form className=' w90 row center' onSubmit={onSubmitSearch} autoComplete='off'>
                            <div className='w20 h4 b10 c3 cont-circ8 row center pp cell-text-smile08' onClick={()=>(setOpenNew(true))&setValues(initialValues)} style={{backgroundColor: TenancyColor().backgroundColor}}>Add</div>
                            <div className='w60'>
                                <input type="text" name="nome" onChange={onChange} placeholder=' Search'/>
                            </div>
                            <div className='w20'>
                                <input type="submit" value="Pesquisar" style={{backgroundColor: TenancyColor().backgroundColor}} className='cell-text-smile08'/>
                            </div>
                        </form>
                        <div className='w90 h20 column'>
                            <label className='w100 ma5 fs1p3em'>{config.pageName} Listados(a)</label>
                           
                            <div className='row between w100 h5 cell-none'>
                                <div className='w7 fs08em fb'>Status:</div>
                                <div className='w10 fs08em fb'>ID</div>
                                <div className='w40 fs08em fb'>Nome:</div>
                                <div className='w30 fs08em fb'>Cadastrado em:</div>
                                <div className='w7 fs08em fb'>Editar</div>
                            </div>
                            {final}
                        </div>     
                    </div>
                </div>
            </div>
            <div className="h75 w100 b17">
               
            </div>
        </section>
      </>
    )
}

