
import { Link, useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import axios from "axios";
import ImgLoading from "../../acets/img_icon/loading.gif"
//import imgBkg0 from'../../acets/img_bkg/bkg_pizza.svg';
import StatusHorarioFuncionamento from "../partils/StatusHorarioFuncionamento";
import TenancyColor from "./TenancyColor";

export default function TeancyAuth(){
    //const datacar = localStorage.getItem("items")
    const [tenancyEmpresa,setTenancyEmpresa]=useState({})
    const capaimglocal = localStorage.getItem('localcapaimg')
    const { id } = useParams()
    const dateH = new Date().toLocaleTimeString();
    
    useEffect(()=>{
        rec();
    },[])
    function rec(){
        localStorage.removeItem("items");
        localStorage.removeItem("localcolor1");
        localStorage.removeItem('splashscreenShown');
        localStorage.removeItem('localpreparo');

        const tenancylocal = localStorage.getItem('ClienteTenancy')
        axios.defaults.headers.common['id_tenancy'] = tenancylocal;
        axios.get('/api/empresa/tenancy/'+id).then(resp=>{
            //localStorage.removeItem("localcapaimg");
            //console.log(resp.data)
            setTenancyEmpresa(resp.data)
            const tenancyfront = resp.data.id_tenancy;
            localStorage.removeItem("localcapaimg");
            localStorage.removeItem("edf5654sde4d888safw2*/#e3ed");
            localStorage.setItem('ClienteTenancy',tenancyfront) 
            localStorage.setItem('localcapaimg',resp.data.capaimg) 
            localStorage.setItem('localcolor1',resp.data.color1) 
            localStorage.setItem('localpreparo',resp.data.preparo) 
            if(resp.data.player_ids){
                axios.defaults.headers.common['player_ids'] = resp.data.player_ids;
            }


            //localStorage.setItem('edf5654sde4d888safw2*/#e3ed',resp.data.pix_estatico) 
            
            //console.log(resp.data)
            //TenancyColor(resp.data.color1)
        })
        
    }
    
    //console.log(localStorage.getItem('localcapaimg'))
    const divStyle = {
        
        backgroundPosition:'center',
        backgroundSize:'cover',
        backgroundRepeat:'no-repeat'
      };
 //<StatusHorarioFuncionamento Abre={tenancyEmpresa}/>
 //console.log(tenancyEmpresa)
    return(
        <section className="w100 h100">
            
            <div className="w100 h90 column center b27">
                
                {
                    //CARREGANDO DADOS
                !tenancyEmpresa.id_tenancy&&<div className="b2 h100 w100 posifixedfim z7 column center fb fs1p5em">Carregando...</div>
                }


                <div style={divStyle} className="h50 w100 top column center  z5 cont-circ-root-bottom">
                   
                </div>

                
                {   
                tenancyEmpresa?
                <div className="column center h70 w40 cell-tamanho90">
                    <h5 className="w100 cen cell-tamanho90 c1 fs2em fb">{id.charAt(0).toUpperCase() + id.slice(1)}</h5>
                    { tenancyEmpresa?
                    <div className="w100 cell-tamanho90">
                        <label className="roboto c1">{'Rua:'+tenancyEmpresa.rua +',  Numero:'+tenancyEmpresa.numero+' - '+ tenancyEmpresa.cidade} - </label>
                        <label className="roboto c1">Obs Agenda:{tenancyEmpresa.obs_agenda}</label>
                    </div>:
                    <div className="column center h100 w40 cell-tamanho80">
                        <h5 className="w100 cen h7">Ops... Link não encontrado</h5>
                        <label>Verifique se o link foi digitado corretamente</label>
                        <Link to="/" className="fb w100 h7 b2 sombra02 column center">Retornar para Cardapio360</Link>
                    </div>
                    }
                   {tenancyEmpresa.abre!=="00:00:00" && tenancyEmpresa.status===1&&
                    <div className="row between w100 h15">
                            <div className="w30 column center">
                                <label className="fb">Status:</label>
                                <label>
                                    {dateH>=tenancyEmpresa.abre&dateH>=dateH?
                                    <label className="fb c10">Aberto</label>
                                    :<label className="fb c9">Fechado</label>}
                                </label>
                            </div>
                            <div className="w30 column center">
                                <label className="fb">Abre:</label>
                                <label>{tenancyEmpresa.abre}</label>
                            </div>
                            <div className="w30 column center">
                                <label className="fb">Fecha:</label>
                                <label>{tenancyEmpresa.fecha}</label>
                            </div>
                        
                        
                        <div className="w100 column center pcb10">
                           
                        </div>
                    </div>}
                   
                    <div className="w100 h50">
                        {tenancyEmpresa.status===1?
                        <Link to="/home" className="b2 sombra02 w100 h7 column ma10b0 cont-circ pp">
                            <label onClick={()=>rec()} className="p20 pp">Cardápio - Delivery</label> 
                        </Link>:
                        <div className="b2 sombra02 w100 h7 column ma10b0 cont-circ pp">
                            <label onClick={()=>alert('O delivery está fechado no momento.')} className="p20 pp">Cardápio - <strong className="fb c9">Delivery Fechado</strong></label>
                        </div>
                        }
                        {tenancyEmpresa.link01&&
                            <a href={tenancyEmpresa.link01} className="b2 sombra02 w100 h7 column ma10b0 cont-circ">
                                <label onClick={()=>rec()} className="p20">ifood</label> 
                            </a>}
                        {tenancyEmpresa.link02&&
                            <a href={tenancyEmpresa.link02} className="b2 sombra02 w100 h7 column ma10b0 cont-circ">
                                <label onClick={()=>rec()} className="p20">Instagram</label> 
                            </a>}
                        {tenancyEmpresa.link04&&
                            <a href={tenancyEmpresa.link04} className="b2 sombra02 w100 h7 column ma10b0 cont-circ">
                                <label onClick={()=>rec()} className="p20">Facebook</label> 
                            </a>}
                            {tenancyEmpresa.link03 && tenancyEmpresa.status === 1 && (
                            <a href={`https://api.whatsapp.com/send?phone=55${tenancyEmpresa.link03}&text=Olá%20%F0%9F%98%84360`} className="b2 sombra02 w100 h7 column ma10b0 cont-circ">
                                <label onClick={rec} className="p20">WhatsApp para tirar dúvidas</label> 
                            </a>
                            )}
                        {tenancyEmpresa.link05&&
                            <a href={tenancyEmpresa.link05} className="b2 sombra02 w100 h7 column ma10b0 cont-circ">
                                <label onClick={()=>rec()} className="p20">Outros Links</label> 
                            </a>}
                    </div>
                </div>:

                <div className='w100 h100 fundo-opaco posiabstop0 left0 column center'>
                    <img src={ImgLoading} alt="carregando" className='w30 wmax50'></img>
                </div>
                }
            </div>

            <div className="w100 h10 b1 column center">
                <Link to="/" className="fb ">C360</Link>
            </div>
        </section>
      

    )
}