import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import IconDashboard from '../../acets/img_icon/menu/dashboard.svg'
import IconNovoOrcamento from '../../acets/img_icon/menu/novoorcamento.svg'
import IconOrcamento from '../../acets/img_icon/menu/orcamentos.svg'
import IconProduto from '../../acets/img_icon/menu/produtos.svg'
import IconFornecedor from '../../acets/img_icon/menu/fornecedor.svg'
import IconCliente from '../../acets/img_icon/menu/clientes.svg'
import IconCutos from '../../acets/img_icon/menu/custos.svg'
import IconEmpresa from '../../acets/img_icon/menu/empresa.svg'
import IconSair from '../../acets/img_icon/menu/sair.svg'

import iconmenulateral from '../../acets/img_icon/menubarralateral/menu.svg'
import iconmenufechalateral from '../../acets/img_icon/menubarralateral/menufecha.svg'
import iconloglateral from '../../acets/img_icon/menubarralateral/log.svg'
import iconnotifilateral from '../../acets/img_icon/menubarralateral/notifica.svg'

export default function MenuPainelAdmin(){
  const tenancylocal = localStorage.getItem('ClienteTenancy')
  axios.defaults.headers.common['id_tenancy'] = tenancylocal;
  
  const navigate = useNavigate();
  const [selected, setSelected] = useState(localStorage.getItem('selected') || '');

  const [openlog,setOpenlog] = useState(false)
  const [openmenu,setOpenMenu] = useState(false)
  const [opennotifica,setOpennotifica] = useState(false)
  
  const [datanotifica,setDatanotifica]=useState([])
  const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
    titulo: '',	descricao: '',	troco: '',bairro:'0',rua:'0',numero:'0',complemento:'0',observacoes:'null',	cliente: '', nome:''
  }
  const [values, setValues]=useState(initialValues) // INICIANDO UM STATE
  const handleClick = (item, path) => {
    setSelected(item);
    localStorage.setItem('selected', item); // Salva no localStorage
    navigate(path);
  };

  function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
    const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV
    setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
  }
  useEffect(()=>{
    rec();
    if ((window.screen.availHeight < 1234) &&(window.screen.availWidth < 1234)){
      //console.log("tela pequena")
    }
  else{
    setOpenMenu(true)
  //console.log("tela pequena")
  }
  setSelected(localStorage.getItem('selected') || '');


    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const deslogar =()=>{
      localStorage.removeItem('keyItemNameg');
      localStorage.removeItem('keyItemName');
      localStorage.removeItem('ItemUsers')
      window.location.href = "/";
    }

    function rec(){
      axios.get('/api/notificacoes').then(resp=>
        setDatanotifica(resp.data)
      )
    }
    function onSubmit(ev){//CADASTRAR NO BANCO DE DADOS, obs refatorado para tirar o if e else e usar o. ternario edit e cadastrar
      ev.preventDefault();
      if(values.descricao===''||values.titulo===''){
          alert("Atencao, preencha todos os campos!")
           }else{
          axios.post('/api/notificacoes',values).then(()=>{
              setValues(initialValues);
              rec();
          })
      }
  }
  const exclui = function(id){//EXCLUI
    axios.delete('/api/notificacoes/' + id).then(()=>{
        rec();
        setValues(initialValues)
    })
}
    const finalnotifica = datanotifica.map(namesString=>
      <div key={namesString.id_notificacao} className="column ma10b0 linha">
        <label className="fs1p1em c1 w90 ma5">{namesString.titulo}</label>
        <label className="w90">{namesString.descricao}</label>
        <label className="fs06em w90 fim">{namesString.createdAt}</label>
        {tenancylocal==='pimotos'&&<button className='w100 btn04 ' onClick={()=>exclui(namesString.id_notificacao)}>Excluir</button>}
      </div>
      
    )
   // console.log(datanotifica)
    return(
      <>
      
      <div className="w20 h90 cont-circ3 column cell-none cell-margin10">
        
      </div>
      <div className="w20 desktop-height-100 cont-circ3 row cell-tamanho cell-margin10 posifixedinicio z15">

        <div className="w15 desktop-height-100 row center pp cell-color-black b13 cell-posifixedbotton z4 " >
          <div className="column center w100 cell-tamanho75 celularrow cellaround">
            {
            openmenu
            ?
            <img 
              src={iconmenufechalateral} 
              className="w60 cell-tamanho10" 
              alt="menu lateral" 
              onClick={() => {
                setOpenMenu(false);
                setOpennotifica(false);
                setOpenlog(false);
              }} 
            />
            :
            <img 
              src={iconmenulateral} 
              className="w60 cell-tamanho10" 
              alt="menu lateral" 
              onClick={() => {
                setOpenMenu(true);
                setOpennotifica(false);
                setOpenlog(false);
              }} 
            />
            
            }
            <img 
              src={iconnotifilateral} 
              className="w60 cell-tamanho10" 
              alt="notificações laterais" 
              onClick={() => {
                setOpennotifica(!opennotifica);
                setOpenMenu(false);
                setOpenlog(false);
              }} 
            />
            <img 
              src={iconloglateral} 
              className="w60 cell-tamanho10" 
              alt="log lateral" 
              onClick={() => {
                setOpenlog(!openlog);
                setOpenMenu(false);
                setOpennotifica(false);
              }} 
            />
          </div>
        </div>
              
        {openlog&&
          <div className="w85 b2 column around he100 sombra-small">
          <div className="w90 h100 column center" style={{ overflowY: 'auto', height: '100%' }}>
            
            <div className="w100 he100" style={{ overflowY: 'auto' }}>
              <div className="w100">
                <label>Registro de log</label>
              </div>
            </div>
          </div>
        </div>
        
        }
        {opennotifica&&
          <div className="w85 b2 column around he100 sombra-small">
          <div className="w90 h100 column center" style={{ overflowY: 'auto', height: '100%' }}>
            
            <div className="w100 he100" style={{ overflowY: 'auto' }}>
              <div className="w100 ma25b0">
                <label>Registro de notificações</label>
              </div>
              {finalnotifica}
              {tenancylocal==='pimotos'&&
              <form onSubmit={onSubmit}>
                <div className="w90">
                  <label>Titulo</label>
                   <input type="text" name="titulo" onChange={onChange} value={values.titulo} autoComplete="off"></input>
                </div>
                <div className="w90">
                  <label>Subtitulo</label>
                  <input type="text"  name="descricao"  onChange={onChange} value={values.descricao} autoComplete='off'></input>
                </div>
                <div className="w90">
                  <input type="submit"/>
                </div>
              </form>}
            </div>
          </div>
        </div>
        }
        {openmenu&&
          <div className="w85 b2 column around h100 sombra-small">
            <label className="fb fs1p1em">
              {tenancylocal.charAt(0).toUpperCase() + tenancylocal.slice(1)}
            </label>
            {//<label onClick={()=>setOpenMenu(false)} className="pp w90 h7 center column b27">Fechar Menu</label>
            }
            <div className="w90 column center">
              <div
                className={`w100 row center ${selected === 'dashboard' ? 'selected' : ''}`}
                onClick={() => handleClick('dashboard', '/painel')}
              >
                <img src={IconDashboard} alt="icone de painel" />
                <span className="fb w79 pc10l5 no-hover pp">Dashboard</span>
              </div>

              <div
                className={`w100 row center ${selected === 'novo' ? 'selected' : ''} cell-paddingcb10px`}
                onClick={() => handleClick('novo', '/adminvendarapida')}
              >
                <img src={IconNovoOrcamento} alt="icone de painel" />
                <span className="fb w79 pc10l5 no-hover pp">Novo Orçamento</span>
              </div>

              <div
                className={`w100 row center ${selected === 'orcamentos' ? 'selected' : ''} cell-paddingcb10px`}
                onClick={() => handleClick('orcamentos', '/adminpedido')}
              >
                <img src={IconOrcamento} alt="icone de painel" />
                <span className="fb w79 pc10l5 no-hover pp">Orçamentos</span>
              </div>

              <div
                className={`w100 row center ${selected === 'produtos' ? 'selected' : ''} cell-paddingcb10px`}
                onClick={() => handleClick('produtos', '/adminproduto')}
              >
                <img src={IconProduto} alt="icone de painel" />
                <span className="fb w79 pc10l5 no-hover pp">Produtos e Serviços</span>
              </div>

              <div
                className={`w100 row center ${selected === 'vendarealizada' ? 'selected' : ''} cell-paddingcb10px`}
                onClick={() => handleClick('vendarealizada', '/adminvendarealizada')}
              >
                <img src={IconCutos} alt="icone de painel" />
                <span className="fb w79 pc10l5 no-hover pp">Faturamento</span>
              </div>

              <div
                className={`w100 row center ${selected === 'fornecedor' ? 'selected' : ''} cell-paddingcb10px`}
                onClick={() => handleClick('fornecedor', '/adminclientefornecedor')}
              >
                <img src={IconFornecedor} alt="icone de painel" />
                <span className="fb w79 pc10l5 no-hover pp">Fornecedor</span>
              </div>

              <div
                className={`w100 row center ${selected === 'clientes' ? 'selected' : ''} cell-paddingcb10px`}
                onClick={() => handleClick('clientes', '/admincliente')}
              >
                <img src={IconCliente} alt="icone de painel" />
                <span className="fb w79 pc10l5 no-hover pp">Clientes</span>
              </div>

              <div
                className={`w100 row center ${selected === 'custos' ? 'selected' : ''} cell-paddingcb10px`}
                onClick={() => handleClick('custos', '/admincustoempresa')}
              >
                <img src={IconCutos} alt="icone de painel" />
                <span className="fb w79 pc10l5 no-hover pp">Custos</span>
              </div>

              <div
                className={`w100 row center ${selected === 'empresa' ? 'selected' : ''}`}
                onClick={() => handleClick('empresa', '/admindadosempresa')}
              >
                <img src={IconEmpresa} alt="icone de painel" />
                <span className="fb w79 pc10l5 no-hover pp">Dados Empresa</span>
              </div>
              <div className="w100 row center cell-paddingcb10px">
                <img src={IconSair} alt="icone de painel" />
                <Link to="/" className="fb w79 pc10l5 pp" onClick={deslogar}>
                  Sair do app
                </Link>
              </div>

            
            </div>
            <label>Auto metricas 	&trade;</label>
             
            
          
{/*
          <Link to="/adminassistenteinteligente" className="fb w90 ma10b0 row">
            <label className="pp">Assistente Inteligente</label>
            <div className="b27 column center cont-circ7 fs06em fb p5 mac0l8 pp"> Paused</div>
          </Link>
        */ }
         
          
          
          {/*
          <Link to="/adminimagen" className="fb w90 ma10b0">Icones</Link>
          <Link to="/admincategoriaproduto" className="fb w90 ma10b0">Categoria de Serviços/Peças</Link>
          <Link to="/adminentregador" className="fb w90 ma10b0 row">
            <label className="pp">Entregador - </label>
            <div className="b27 column center cont-circ7 fs06em fb p5 mac0l8 pp"> Novo</div>
          </Link>
          <Link to="/adminfinanceirotenancy" className="fb w90 ma10b0 row">
            <label className="pp">Financeiro</label> 
            <div className="b27 column center cont-circ7 fs06em fb p5 mac0l8 pp"> Novo</div>
          </Link>
          <Link to="/adminfrete" className=" w90 ma10b0 row">
            <label className="fb pp">Frete - </label>
            <div className="b27 column center cont-circ7 fs06em fb p5 mac0l8 pp"> Novo</div>
          </Link>
        
          <Link to="/adminfeature" className="fb w90 ma10b0">Feature<span className="fs08em text-smile-weight"> - Atualizações</span></Link>
          */}
          {
            // <Link to="/admindadosempresa" className="fb w90 ma10b0">Etiqueta e Patrimonio</Link>
            //<Link to="/adminprodutoestoque" className="fb w90 ma10b0">Estoque de produtos</Link>
          
          
            //<Link to="/adminlead" className="fb w90 ma10b0">Leads</Link>
          }
{//<Link to="/areadocliente/orcamentoAuth0/154/138" className="fb w90 ma10b0">Area do Cliente</Link>
}
      
        </div>
        }
        
        
      </div>
      </>
    )
}