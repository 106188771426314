import axios from "axios";
import { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import MenuPainelAdmin from '../partils/AdminMenuPainel'
import { Chart } from "react-google-charts";


import TenancyColor from "../midllers/TenancyColor";
import { useNavigate } from 'react-router-dom';

import ChartPedidosMes from'../partils/ChartPedidosMes'
import ChartFormaPG from'../partils/ChartFormaPG'
import ChartProdutosMaisVendidos from '../partils/ChartProdutosMaisVendidos'
import ChartCampanhaInstagram from '../partils/ChartCampanhaInstagram'
import ChartReceitaMes from'../partils/ChartReceitaMes'

import ImgMoreData from'../../acets/img_icon/moredatadash.svg'

import AdminHeader from "../partils/AdminHeader";

export default function Painel(){
   const navigate = useNavigate();
   const tlocal = localStorage.getItem('keyItemNameg')
   axios.defaults.headers.common['token'] = tlocal;

   const initialvalues ={totalFaturamento:0,valor_baixa:0,countOrcamento:'0',produto:'0',servico:'0',totalpivoorcaprod:'0',totalcusto:'0',totalclientefornecedoroficina:'0',repasserealizado:'0'}

   const [produto,setProduto]=useState(0)
   const [orcamento,setOrcamento]=useState(0)
   const [feedback,setFeedback]=useState(0)
   const [faturamento,setFaturamento]=useState(0)
   const [data,setData]=useState()
   const [checkout,setCheckout]=useState([])
   const [fatTotal,setFatTotal]=useState(0)
   const [values,setValues]=useState({initialvalues})
   const [showfilters, setShowfilters]=useState(false)
   const [showDataNunber,setShowDataNunber]=useState(false)

   const [dataformapg,setDataformapg]=useState([])
   const [datadevedor,setDatadevedor]=useState([])

   // Obter a data de hoje no formato 'YYYY-MM-DD'
   const today = new Date();
   const year = today.getFullYear();
   const month = String(today.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
   const day = String(today.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
   const formattedToday = `${year}-${month}-${Number(day) + 1}`;

   
   const [intervalfinalDate, setIntervalfinalDate] = useState(formattedToday);
   const [intervalinitialDate,setIntervalinitialDate]=useState('2024-02-01')



   const tenancylocal = localStorage.getItem('ClienteTenancy')

   useEffect(()=>{
      rec();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])
      function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES 
         axios.get('/api/empresa/tenancy/one/' + tenancylocal)
            .then(resp => {
              const invalid = {ERRO: 'token invalido 3'};
                if (JSON.stringify(resp.data[0]) === JSON.stringify(invalid)) {
                  navigate('/login');
                  window.location.reload(true);
                }
                else{
                  setData(resp.data);
                }
            })
            .catch(error => {
              console.log(error);
              return <div>{error.message || error.toString()}</div>; // renderiza a mensagem de erro como uma string
            })
            .catch(err => console.log(err));

            axios.get(`/api/dashboard/orcamento/conttotal/${intervalinitialDate}/${intervalfinalDate}`)
            .then(resp => {
               //console.log(resp.data.total)
                setValues(prevValues => ({
                    ...prevValues,
                    totalFaturamento: resp.data.total
                }));
            })
            axios.get(`/api/dashboard/orcamento/cont/${intervalinitialDate}/${intervalfinalDate}`)
            .then(resp => {
               //console.log(resp.data)
                setValues(prevValues => ({
                    ...prevValues,
                    countOrcamento: resp.data
                }));
            })
            axios.get(`/api/dashboard/pivo/baixaorcamento/${intervalinitialDate}/${intervalfinalDate}`)
            .then(resp => {
               //console.log(resp.data.valor_baixa)
                setValues(prevValues => ({
                    ...prevValues,
                    valor_baixa: resp.data.valor_baixa
                }));
            })
            
            axios.get(`/api/dashboard/pivo/baixaorcamentotipo/${intervalinitialDate}/${intervalfinalDate}`)
            .then(resp => {
               //console.log(resp.data)
               setDataformapg(resp.data)
            })
            axios.get(`/api/dashboard/produto/count/${intervalinitialDate}/${intervalfinalDate}`)
            .then(resp => {
               //console.log(resp.data)
                setValues(prevValues => ({
                    ...prevValues,
                    produto: resp.data
                }));
            })
            axios.get(`/api/dashboard/servico/count/${intervalinitialDate}/${intervalfinalDate}`)
            .then(resp => {
               //console.log(resp.data)
                setValues(prevValues => ({
                    ...prevValues,
                    servico: resp.data
                }));
            })
            axios.get(`/api/dashboard/pivoorcaprod/counttotal/${intervalinitialDate}/${intervalfinalDate}`)
            .then(resp => {
               //console.log(resp.data.preco)
                setValues(prevValues => ({
                    ...prevValues,
                    totalpivoorcaprod: resp.data.preco
                }));
            })
            //oficina
            axios.get(`/api/dashboard/pivoorcaprod/counttotal/oficina/${intervalinitialDate}/${intervalfinalDate}`)
            .then(resp => {
               //console.log(resp.data)
                setValues(prevValues => ({
                    ...prevValues,
                    totalclientefornecedoroficina: resp.data.preco
                }));
            })
            

            axios.get(`/api/dashboard/custo/conttotal/${intervalinitialDate}/${intervalfinalDate}`)
            .then(resp => {
                setValues(prevValues => ({
                    ...prevValues,
                    totalcusto: resp.data.valor
                }));
            })
           {/* axios.get(`/api/clientefornecedor/3`)
            .then(resp => {
               //console.log(resp.data)
                setValues(prevValues => ({
                    ...prevValues,
                    totalclientefornecedoroficina: resp.data[0].saldo
                }));
            })
         */}
            axios.get(`/api/orcamento/${intervalinitialDate}/${intervalfinalDate}`)
            .then(resp =>{
               //console.log(resp.data)
               setOrcamento(resp.data)
            }) 
            axios.get(`/api/dashboard/pivo/baixaclientefornecedor/${intervalinitialDate}/${intervalfinalDate}`)
            .then(resp =>{
               //console.log(resp.data.valor_baixa)
               setValues(prevValues => ({
                  ...prevValues,
                  repasserealizado: resp.data.valor_baixa
              }));
            }) 
            axios.get(`/api/dashboard/orcamento/faltantes`)
            .then(resp =>{
               //console.log(resp.data)
               setDatadevedor(resp.data)
            })
     }
    //console.log(pedido[0])
    
    const totalGanho = fatTotal&&fatTotal.reduce((acc, item) => {
     return acc + item.produto.preco_final;
    }, 0);
  
    const valorTotal = values.totalFaturamento;
    const valorParcial = values.valor_baixa-(values.totalFaturamento-values.totalclientefornecedoroficina)-values.totalcusto;
    
    const porcentagem = (valorParcial / valorTotal) * 100;
    
    //console.log(datadevedor)
    
   const rendermapdevedores = datadevedor.map(resp=>
      <div className="w90 row between pcb10 linha" key={resp.id_orcamento_venda}>
         <label className="w40">{resp.cliente.nome}</label>
         <div className="fb w25">
            <label className="fs06em">R$:</label>
            <label className="fb">{resp.valor_falta}</label>
         </div>
         <label className="fs09em fb w15 cell-none">{resp.createdAt.slice(0,10)}</label>
         <div
            className="w20 b10 c3 pcb7 pp cont-circ3 column center"
            onClick={() => {
               if (resp.cliente.telefone) {
                  const message = `Olá, percebemos que o orçamento de código ${resp.id_orcamento_venda} está com o valor de R$: ${resp.valor_falta} em aberto. Realize o pagamento e volte a ficar adimplente. para ter acesso ao orçamento digite "orçamento" na conversa do WhatsApp.     Visite o nosso site https://www.autometricas.com.br`;
                  const url = `https://wa.me/${resp.cliente.telefone}?text=${encodeURIComponent(message)}`;
                  window.open(url, '_blank');
               } else {
                  alert('Número de telefone não disponível.');
               }
            }}
            >
            Cobrar
         </div>


      </div>
   )

     //style={{backgroundColor: TenancyColor().backgroundColor}}
    return(
      <section className='h100 row between b31'>
         <AdminHeader props='Dashboard'/>
         <MenuPainelAdmin/>
         <div className="h20 w80  row center celular cell-tamanho ">
          
          <div className="w90 row between cell-tamanho90">
                     <div className="w100 fim pp p5" onClick={()=>setShowfilters(!showfilters)}>
                        <label 
                           className="c1 fs08em b2 pp">
                           {showfilters?'Fechar Filtros':'Abrir Filtros'}
                        </label>
                     </div>
                     

                     {showfilters&&
                     <div className="w100 b2 c3 b2 sombra-small flex row center ma10b0">
                        <label className="w95 ma5 ">Selecione o intervalo de datas para obter os dados</label> 
                        <div className="w95 row between">
                           <div className="w33">
                              <label className="fs08em">Início</label>
                              <input 
                                 type="date"
                                 onChange={(e) => setIntervalinitialDate(e.target.value)}
                              />
                           </div>
                           <div className="w33">
                              <label className="fs08em">Fim</label>
                              <input 
                                 type="date"
                                 onChange={(e) => setIntervalfinalDate(e.target.value)}
                              />
                           </div>
                           <div className="w25">
                              <label>Ação</label>
                              <button className="w95" onClick={()=>rec()}>Ir</button>
                           </div>
                           <label className="fs08em ma5">
                              {"De: "+intervalinitialDate + " até " + intervalfinalDate} 
                           </label>
                        </div>
                        
                     </div>
                     }

                     {
                        <div>
                        
                        </div>
                     }

                     <div className="w100 row between b2 cont-circ8 ma10b0 sombra-small"> 
                        <div className="h10  w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5 pp">
                        <label className="ma10b0 fb c1">Orçamentos</label>
                        {/*<label className="fs06em cen c1">Quantidade na data </label>*/}
                        <label className=" fb h5 column center c4">{values.countOrcamento}</label>
                        </div>

                     

                        <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5">
                        <label className=" ma10b0 fb c1">Faturamento</label>
                        {/*<label className="fs06em cen c1">Tudo o que entrou</label>*/}
                        <div className="row">
                           <label className="fs06em">R$: </label>
                           <label className={` fb h5 column center c4" ${values.totalFaturamento == values.totalpivoorcaprod ? 'c4' : 'c9 b1'}`}> {values.totalFaturamento}</label>
                        </div>
                        
                        
                        
                     </div>
                     <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5" >
                        <label className=" ma10b0 fb c1" >Recebido</label>
                        {/*<label className="fs06em cen c1">Baixas nos orcamentos</label>*/}
                        <div className="row">
                           <label className="fs06em">R$: </label>
                           <label className=" fb h5 column center c4">{values.valor_baixa}</label>
                        </div>
                     </div>
                    
                     <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5">
                        <label className=" ma10b0 c1 fb">A receber</label>
                        {/*<label className="fs06em cen c1">Fiados</label>*/}
                        <div className="row">
                           <label className="fs06em">R$: </label>
                           <label className=" fb h5 column center c4">{values.totalFaturamento-values.valor_baixa}</label>
                        </div>
                     </div>
                     {!showDataNunber&&
                     <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5 pp" 
                     onClick={()=>setShowDataNunber(!showDataNunber)}
                        >
                        <label className=" ma10b0 fb c1" >Mais dados</label>
                        <img src={ImgMoreData} alt="Mais dados"/>
                     </div>
                     }
                     {showDataNunber&&
                     <>
                     <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5">
                        <label className=" ma10b0 c1 fb">Custos</label>
                        {/*<label className="fs06em cen c1">Aluguel + salários</label>*/}
                        <div className="row">
                        <label className="fs06em">R$: </label>
                           <label className=" fb  h5 column center c4">{values.totalcusto}</label>
                        </div>
                     </div>
                     <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5">
                        <label className="ma10b0 c1 fb">Fornecedor</label>
                        {/*<label className="fs06em cen c1">Deve repass Fornecedores</label>*/}
                        <div className="row">
                           <label className="fs06em">R$: </label>
                           <label className="h5 fb column center c4">{values.totalFaturamento-values.totalclientefornecedoroficina}</label>
                        </div>
                     </div>
                     

                    
   {/*
                     <div className="h10 w15 b2 cont-circ3 sombra column start cell-tamanho-metade30 cell-margin5" >
                        <label className=" ma10b0 " >Serviços</label>
                        <label className="fs06em">Tipos de Serviços</label>
                        <label className=" fb h10 column center">{values.servico}</label>
                     </div>
   */}
                     <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5">
                        <label className=" ma10b0 cen c1 fb">Serviços oficina</label>
                        {/*<label className="fs06em cen c1">Mão de obra Total, recebido e a receber</label>*/}
                        <div className="row">
                           <label className="fs06em">R$: </label>
                           <label className="fb h5 column center c4">{values.totalclientefornecedoroficina}</label>
                        </div>
                     </div>
                     <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5">
                        <label className=" ma10b0 c1 fb">Lucro Bruto</label>
                        {/*<label className="fs06em cen c1">Faturamento - custos - fornecedores</label>*/}
                        <div className="row">
                           <label className="fs06em">R$: </label>
                           <label className="fb h5 column center c4">{values.totalFaturamento-values.totalcusto-(values.totalFaturamento-values.totalclientefornecedoroficina)}</label>
                        </div>
                     </div>
                     <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5">
                        <label className=" ma10b0 cen c1 fb">Lucro recebido</label>
                        {/*<label className="fs06em cen c1">Recebido - fornecedores</label>*/}
                        <div className="row">
                           <label className="fs06em">R$: </label>
                           <label className="fb h5 column center c4">{values.valor_baixa-(values.totalFaturamento-values.totalclientefornecedoroficina)}</label>
                        </div>
                     </div>
                     <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5">
                        <label className=" ma10b0 cen c1 fb">Lucro L. rcb</label>
                        {/*<label className="fs06em cen c1">Recebido - fornecedores - custos</label>*/}
                        <div className="row">
                           <label className="fs06em">R$: </label>
                           <label className="h5 column center c4 fb">{values.valor_baixa-(values.totalFaturamento-values.totalclientefornecedoroficina)-values.totalcusto}</label>
                        </div>
                     </div>
                     <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5">
                        <label className=" ma10b0 cen c1 fb">Repasse Rlzdo.</label>
                        {/*<label className="fs06em cen c1">Transferencia ao fornecedores</label>*/}
                        <div className="row">
                           <label className="fs06em">R$: </label>
                           <label className="h5 column center c4 fb">{values.repasserealizado}</label>
                        </div>
                     </div>
                     <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5">
                        <label className=" ma10b0 cen c1 fb">Repasse Pend.</label>
                        {/*<label className="fs06em cen c1">Pagamentos pendentes aos fornecedores</label>*/}
                        <div className="row">
                           <label className="fs06em">R$: </label>
                           <label className="h5 column center c4 fb">{(values.totalFaturamento-values.totalclientefornecedoroficina)-values.repasserealizado}</label>
                        </div>
                     </div>
                     {/*
                     <div className="h10 w15 b2 cont-circ3 sombra column start cell-tamanho-metade30 cell-margin5">
                        <label className=" ma10b0">Produtos</label>
                        <label className="fs06em">Produtos em estoque</label>
                        <label className=" fb h10 column center">{values.produto}</label>
                        </div>*/}
                     </>}
                     <div 
                        className="w100 h5 column center"
                        onClick={()=>setShowDataNunber(!showDataNunber)}>
                        {showDataNunber?
                        <label className="c1 fb fs08em pp">Minimizar dados</label>:
                        <label className="c1 fb fs08em pp">Mostrar mais dados</label>
                        }
                     </div>
                    </div>
                     
                    <div className="h10 w100 cont-circ3 b2  ma10b0 column center sombra-small">
                        <h5 className="w90">Margem</h5>
                        <div className="w90 h5 cont-circ7 borda1 ma10b0">
                        <div className="b28 h5 column center " style={{ width: `${Math.min(porcentagem, 100).toFixed(2)}%` }}>
                        <label className="c1 fb">{porcentagem.toFixed(2)}%</label>
                           </div>
                        </div>
                     </div>
                    
                    <div className="h20 w100 b2 cont-circ3 b2 ma10b0 column center sombra-small">
                     {orcamento?
                        <ChartPedidosMes pedidos={orcamento}/>:
                        <label>NaN</label>
                     } 
                    </div>
                    
                    {/*
                    <div className="h20 w100 b2 cont-circ3 sombra ma25b0 column center">
                    <label className="cen">Receita diaria por Funcionario - em desenvolvimento</label>
                    {orcamento==="gg"&&
                        <ChartProdutosMaisVendidos/>
                     } 
                    </div>
                    */}
                    
                    <div className="h10 w100 b2 cont-circ3 b2  ma10b0 column center sombra-small">
                    
                     {orcamento&&
                        <ChartFormaPG pg={dataformapg}/>
                     } 
                     
                    </div>
                    {/*
                    <div className="h20 w100 b2 cont-circ3 sombra ma25b0 column center">
                    <label className="cen">Historico de Lucro liquido por mês</label>
                     {orcamento==="gg"&&
                        <ChartCampanhaInstagram/>
                     } 
                    </div>
                     */}
                    <div className="h20 w100 b2 cont-circ3 b2 column center mac0b10vh sombra-small">
                     <h5 className="w90 ">Maiores Devedores</h5>
                     {rendermapdevedores}
                    </div>
                </div>
                
               

            </div>
           
      </section>
    )
}
//67982113560 itau
