/*import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// COMPONENTES PARTES
import MenuPainelAdmin from '../partils/AdminMenuPainel';
import TenancyColor from '../midllers/TenancyColor';
import AdminHeader from '../partils/AdminHeader';

// INPORTAÇÕES DE ICONES E IMAGENS
import IconChecked from '../../acets/img_icon/checked.svg';
import IconNoChecked from '../../acets/img_icon/nochecked.svg';
import IconEdit from '../../acets/img_icon/edit.svg';
import IcoStatus from '../partils/IcoStatus';

export default function AdminCliente() {
  const navigate = useNavigate(); 
  // CONFIGURAÇÕES
  const config = {
    pageName: 'Cliente',
    mainstring: 'nome',
    url: '/api/cliente/',
    url2: '',
    urlPage:'/api/clientePage',
    search: '/api/cliente/search/',
    urlOptions1: '',
    params: '',
    messegerError: 'ATENÇÃO - Preencha os campos para salvar os dados ;D',
  }; 
  const initialValues = {
    nome: '',
    estado: '',
    cidade: '',
    referencia: '',
    telefone: '',
    email: '',
    senha: '',
    pix:'0'
  };
  const [openNew, setOpenNew] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // INICIANDO CICLO DE ESTADOS
  useEffect(() => {
    rec();
  }, [currentPage]);
  // FUNÇÕES
  const tlocal = localStorage.getItem('keyItemNameg');
  const headers = {
    'Content-Type': 'application/json',
    token: tlocal,
  };

  function rec() {
    axios
      .get(config.url, { headers })
      .then((resp) => {
        const invalid = { ERRO: 'token invalido 3' };
        if (JSON.stringify(resp.data[0]) === JSON.stringify(invalid)) {
          navigate('/login');
          window.location.reload(true);
        } else {
          setData(resp.data.rows);
          setTotalPages(resp.data.count/30+1);
          console.log(resp.data)
        }
      })
      .catch((error) => {
        console.error('Erro ao buscar clientes:', error);
      });
  }

  function onChange(ev) {
    const { name, value } = ev.target;
    setValues({ ...values, [name]: value });
  }

  function onSubmit(ev) {
    ev.preventDefault();
    axios[values.id_cliente ? 'put' : 'post'](config.url, values, { headers }).then(() => {
      setOpenNew(false);
      setValues(initialValues);
      rec();
    });
  }

  const editar = function (id) {
    setOpenNew(true);
    axios.get(config.url + id, { headers }).then((respp) => {
      setValues(respp.data[0]);
    });
  };

  const exclui = function (id) {
    axios.delete(config.url + id, { headers }).then(() => {
      rec();
      setOpenNew(false);
      setValues(initialValues);
    });
  };

  function onSubmitSearch(ev) {
    ev.preventDefault();
    axios.post(config.search, values, { headers }).then((respp) => {
      setData(respp.data);
    });
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
    rec();
  };
//console.log(data)
  // COMPONENTES DE LOOP
  const final = data.map((namesString) => (
    <div className='w100 h4 row ma5 between linha' key={namesString.id_cliente}>
      <div className="w5">
        <IcoStatus props={1}/>
      </div>
      <div className='w5 cell-tamanho10'>{namesString.id_cliente}</div>
      <div className='w35 cell-tamanho80 fb'>{namesString.nome}</div>
      <div className='w15 c4 cell-tamanho'><label className='desktop-none'>Cell:</label>{namesString.telefone}</div>
      <div className='w10 cell-tamanho cell-margin5 c4'>{namesString.estado}</div>
      <div className='w20 cell-texto-p c4'>{namesString.cidade}</div>
      <div className='w5  fs08em pp'>
        <b onClick={() => editar(namesString.id_cliente)}>
          <img src={IconEdit} alt="Editar" />
        </b>
      </div>
      {namesString.email&&<div className='w100 cell-texto-p c4'>Email: {namesString.email}</div>}
      {namesString.referencia&&<div className='w100 cell-texto-p c4'>Referencia{namesString.referencia}</div>}
    </div>
  ));

  // PAGINAÇÃO
  const paginationButtons = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationButtons.push(
      <button
        key={i}
        onClick={() => handlePageChange(i)}
        //className={currentPage === i ? 'active' : ''}
        style={{ backgroundColor: TenancyColor().backgroundColor }}
      >
        {i}
      </button>
    );
  }

  return (
    <>
      {openNew&&           
            <div className='w100 h100 posifixedinicio column center z4' >
                <div className='w100 h100 posifixedinicio fundo-opaco z5' onClick={()=>(setOpenNew(false) & setValues(initialValues))}>
                </div>
                <div className='h20 w35 b2 cont-circ3 sombra column center z10 cell-tamanho90'>
                    <div className='w90 h20'>
                        <div className='w100'>
                            {values.id_cliente?<h5>Editar {config.pageName}</h5>:<h5>Cadastrar novo(a) {config.pageName}</h5>  }
                        </div>
                        <form onSubmit={onSubmit} autoComplete='off'>
                            <div>
                                <label>Nome:</label>
                                <input type="text"  name="nome"  onChange={onChange} value={values.nome} autoComplete='off'></input>
                            </div>
                            <div>
                                <label>Estado:</label>
                                <select type="text"  name="estado"  onChange={onChange} >
                                    <option value={"AC"}> Acre</option>
                                    <option value={"AL"}> Alagoas</option>
                                    <option value={"AP"}> Amapá</option>
                                    <option value={"AM"}> Amazonas</option>
                                    <option value={"BA"}> Bahia</option>
                                    <option value={"CE"}> Ceara</option>
                                    <option value={"DF"}> Distrito Federal</option>
                                    <option value={"ES"}> Espírito Santo</option>
                                    <option value={"GO"}> Goiás</option>
                                    <option value={"MA"}> Maranhão</option>
                                    <option value={"MT"}> Mato Grosso</option>
                                    <option value={"MS"}> Mato Grosso do Sul</option>
                                    <option value={"MG"}> Minas Gerais</option>
                                    <option value={"PA"}> Pará</option>
                                    <option value={"PB"}> Paraíba</option>
                                    <option value={"PR"}> Paraná</option>
                                    <option value={"PE"}> Pernambuco</option>
                                    <option value={"RJ"}> Rio de Janeiro</option>
                                    <option value={"RN"}> Rio Grande do Norte</option>
                                    <option value={"RS"}> Rio Grande do Sul</option>
                                    <option value={"RO"}> Rondônia</option>
                                    <option value={"RR"}> Roraima</option>
                                    <option value={"SC"}> Santa Catarina</option>
                                    <option value={"SP"}> São Paulo</option>
                                    <option value={"SE"}> Sergipe</option>
                                    <option value={"TO"}> Tocantins</option>
                                   
                                </select>
                            </div>
                            <div className='row between w100'>
                                <div className='w30'>
                                    <label>Cidade:</label>
                                    <input type="text"  name="cidade"  onChange={onChange} value={values.cidade}></input>
                                </div>
                                <div className='w30'>
                                    <label>Email:</label>
                                    <input type="text"  name="email"  onChange={onChange} value={values.email}></input>
                                </div>
                                <div className='w30'>
                                    <label>Senha:</label>
                                    <input type="text"  name="senha"  onChange={onChange} value={values.senha}></input>
                                </div>
                            </div>
                            <div>
                                <label>Referencia:</label>
                                <input type="text"  name="referencia"  onChange={onChange} value={values.referencia}></input>
                                
                            </div>
                            <div>
                                <label>Telefone:</label>
                                <input type="text"  name="telefone"  onChange={onChange} value={values.telefone}></input>
                            </div>
                           
                            <div className='cell-margin5'>
                            <button type="submit" className='w100'>{values.id_cliente ? 'Atualizar' : 'Cadastrar'}</button>
                            </div>
                        </form>
                        <div className='w100 row between'>
                            {values.id_cliente?<button className='w25 btn04 cell-tamanho-metade' onClick={()=>exclui(values.id_cliente)}>Excluir</button>:<label className='w40'>Sem mais opções</label>}
                            <button onClick={()=>(setOpenNew(false) & setValues(initialValues))} className="w40 ma10b0 btn05">Cancelar</button>
                        </div>
                        
                    </div>
                </div>
            </div>
        }
      <section className='h90 column center b15 b30'>
            <AdminHeader props={config.pageName}/>
            <div className="h20 w90 posiabstop row between aligitensstart celular">
                <MenuPainelAdmin/>
                <div className="w70 row between cell-tamanho">
                    <div className="h20 w100 b2 cont-circ3 sombra column center">
                        <h5 className='w90'>Pesquisar {config.pageName}</h5>
                        <form className=' w90 row center' onSubmit={onSubmitSearch} autoComplete='off'>
                            <div className='w20 h4 b10 c3 cont-circ8 row center pp cell-text-smile08' onClick={()=>(setOpenNew(true))&setValues(initialValues)} style={{backgroundColor: TenancyColor().backgroundColor}}>Add</div>
                            <div className='w60'>
                                <input type="text" name="nome" onChange={onChange} placeholder=' Search'/>
                            </div>
                            <div className='w20'>
                                <input type="submit" value="Pesquisar" style={{backgroundColor: TenancyColor().backgroundColor}} className='cell-text-smile08'/>
                            </div>
                        </form>
                        <div className='w90 h20 column'>
                            <label className='w100 ma5 fs1p3em'>{config.pageName} Listados(a)</label>
                            <div className='row between w100 cell-none'>
                              <div className='w5'>Sta:</div>
                              <div className='w5 cell-tamanho10'>ID:</div>
                              <div className='w35 cell-tamanho80'>Nome:</div>
                              <div className='w15 cell-texto-p c4'>Telefone:</div>
                              <div className='w10 cell-tamanho cell-margin5 c4'>Estado:</div>
                              <div className='w20 cell-texto-p c4'>Cidade:</div>
                              <div className='w5 fs08em'>Editar</div>
                            </div>
                            {final}
                            <div className="h15 column center w100 b17  overflowShow" >
                              <div className="pagination">{paginationButtons}</div>
                            </div>
                        </div>     
                    </div>
                </div>
            </div>
            <div className="h75 w100 b17">
               
            </div>
        </section>
    </>
  );
}
*/




import { useEffect, useState } from 'react'
import axios from 'axios'
//COMPONENTES PARTES

//INPORTAÇOES DE ICONES E IMAGENS
import IconChecked from '../../acets/img_icon/checked.svg'


import MenuPainelAdmin from '../partils/AdminMenuPainel'
import IconNoChecked from '../../acets/img_icon/nochecked.svg'
import IconEdit from '../../acets/img_icon/edit.svg'

import TenancyColor from '../midllers/TenancyColor'
import { useNavigate } from 'react-router-dom';
import AdminHeader from '../partils/AdminHeader'

export default function AdminCliente(){
    const navigate = useNavigate();
    //CONGIGURAÇÕES=============================
    const config={
        pageName:'Cliente',
        mainstring:'nome',
        url:'/api/cliente/',
        url2:'',
        search:'/api/cliente/search/',
        urlOptions1:'',
        params:'',
        messegerError:'ATENÇÃO - Preencha os campos para salvar os dados ;D',
    }
     //INICIANDO CICLO DE ESTADOS
     useEffect(()=>{
        rec();

        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
    //STATES======================================
    //id_cliente	nome	placa	carro	endereco	telefone	email	status	observacao	codigo_acess	tenancyId
    //id_cliente: '',	nome: '',	placa: '',	carro: '',	endereco: '',	telefone: '',	email: '',	status: '',	observacao: '',	codigo_acess: '',	tenancyId: '',
    const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
      id_cliente: '',	nome: '',	placa: '',	carro: '',	endereco: '',	telefone: '',	email: '',	status: '',	observacao: '',	codigo_acess: ''
    }
    const [openNew,setOpenNew]=useState(false)// ABRIR TELA DE CADASTRAR E EDITAR
    const [values, setValues]=useState(initialValues) // INICIANDO UM STATE
    const [data, setData] = useState([])
    //FUNÇÕES=====================================
    const tlocal = localStorage.getItem('keyItemNameg')
    const headers = {
        'Content-Type': 'application/json',
        'token':tlocal,
    };
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
        axios.get(config.url,{headers}).then(resp=>{
            const invalid = {ERRO: 'token invalido 3'};
            if (JSON.stringify(resp.data[0]) === JSON.stringify(invalid)) {
              navigate('/login');
              window.location.reload(true);
            }
            else{
              setData(resp.data);
            }
        })
        //console.log(data[0])
        
    }
    function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
        const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV
        setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
    }
    function onSubmit(ev){//CADASTRAR NO BANCO DE DADOS, obs refatorado para tirar o if e else e usar o. ternario edit e cadastrar
        ev.preventDefault();
        if(values.nome===''){
          alert("Atencao, preencha todos os campos!")
           }else{
            axios[values.id_cliente?'put':'post'](config.url,values,{headers}).then(()=>{
                setOpenNew(false)
                setValues(initialValues);
                rec();
            })
          }
    }
    const editar = function(id){//EDITA VER CARREGA OS DADOS DO ITEM A SER EDITADO
        setOpenNew(true)
        axios.get(config.url + id,{headers}).then(respp=>{
            setValues(respp.data[0])
            })
    }
    const exclui = function(id){//EXCLUI
        axios.delete(config.url + id,{headers}).then(()=>{
            rec();
            setOpenNew(false)
            setValues(initialValues)
        })
    }
    function onSubmitSearch(ev){//PESQUISA
        ev.preventDefault();
        axios.post(config.search, values,{headers}).then(respp=>{
            setData(respp.data)       
        })
       
    }
    //console.log(data[0].ERRO==='token invalido 3')
    //COMPONENTES DE LOOP==============================
    const final = data.map(
        namesString =>
            <div className='w100 h4 row ma5 between linha'  key={namesString.id_cliente}> 
                {//<div className='w5'>{namesString.status===1?<img src={IconChecked}/>:<img src={IconNoChecked}/>}</div>
                //<div className='w5 cell-tamanho10'>{namesString.id_cliente}</div>
                }<div className='w15 cell-tamanho80 fb'>{namesString.nome}</div>
                <div className='w10 cell-tamanho cell-margin5 c4'>{namesString.carro}</div>
                
                <div className='w10 cell-texto-p c4 cell-tamanho cell-none'> {namesString.endereco}</div>
                <div className='w10 cell-texto-p c4 cell-tamanho'> {namesString.telefone}</div>
                
                <div className='w10 cell-texto-p c4 cell-tamanho'> {namesString.email}</div>
            
                <div className="w5 h4 row end pp cell-tamanho ">
                    <b onClick={()=>editar(namesString.id_cliente)}> <img src={IconEdit}></img> </b>
                </div>
            </div>
    )

      //id_cliente	nome	placa	carro	endereco	telefone	email	status	observacao	codigo_acess	tenancyId
    return(
        <>
        {openNew&&           
            <div className='w100 h100 posifixedinicio column center z17' >
                <div className='w100 h100 posifixedinicio fundo-opaco z5' onClick={()=>(setOpenNew(false) & setValues(initialValues))}>
                </div>
                <div className='h20 w35 b2 cont-circ3 sombra column center z10 cell-tamanho90'>
                    <div className='w90 h20'>
                        <div className='w100'>
                            {values.id_cliente?<h5>Editar {config.pageName}</h5>:<h5>Cadastrar novo(a) {config.pageName}</h5>  }
                        </div>
                        <form onSubmit={onSubmit} autoComplete='off'>
                            <div className='ma5'>
                                <label className='c4 fs09em'>Campos obrigatórios <span className='c9'>*</span> </label>
                            </div>
                            <div>
                                <label>Nome <span className='c9'>*</span></label>
                                <input type="text"  name="nome"  onChange={onChange} value={values.nome} autoComplete='off'></input>
                            </div>
                            <div>
                                <label>Endereco:</label>
                                <input type="text"  name="endereco"  onChange={onChange} value={values.endereco}>
                                </input>
                            </div>
                            <div className='row between w100'>
                                <div className='w30'>
                                    <label>Placa <span className='c9'>*</span></label>
                                    <input type="text"  name="placa"  onChange={onChange} value={values.placa}></input>
                                </div>
                                <div className='w30'>
                                    <label>Carro <span className='c9'>*</span></label>
                                    <input type="text"  name="carro"  onChange={onChange} value={values.carro}></input>
                                </div>
                                <div className='w30'>
                                    <label>Telefone:</label>
                                    <input type="number"  name="telefone"  onChange={onChange} value={values.telefone}></input>
                                </div>
                            </div>
                            <div className='row between w100'>
                            
                                <div className='w100'>
                                    <label>Email:</label>
                                    <input type="text"  name="email"  onChange={onChange} value={values.email}></input>
                                </div>
                                
                            </div>
                            <div>
                                <label>Observacao:</label>
                                <input type="text"  name="observacao"  onChange={onChange} value={values.observacao}></input>
                            </div>
                           
                            <div className='cell-margin5'>
                            <button type="submit" className='w100'>{values.id_cliente ? 'Atualizar' : 'Cadastrar'}</button>
                            </div>
                        </form>
                        <div className='w100 row between'>
                            <label className='w40'>Sem mais opções</label>
                            <button onClick={()=>(setOpenNew(false) & setValues(initialValues))} className="w40 ma10b0 btn05">Cancelar</button>
                        </div>
                        
                    </div>
                </div>
            </div>
        }
        
        <section className='h100 row between b31'>
            
            <MenuPainelAdmin/>
            <div className="h100 w80 cell-tamanho row center  celular">
                    
                <div className="w90 row between cell-tamanho celular">
                    <div className="h100 w100 cont-circ3 column  cell-tamanho cell-paddingcb10">
                        <h5 className='w90'>Pesquisar {config.pageName}</h5>
                        <form className=' w90 row center' onSubmit={onSubmitSearch} autoComplete='off'>
                            <div className='btn10' onClick={()=>(setOpenNew(true))&setValues(initialValues)} style={{backgroundColor: TenancyColor().backgroundColor}}>Add</div>
                            <div className='w60'>
                                <input type="text" name="nome" onChange={onChange} placeholder=' Search'/>
                            </div>
                            <div className='w20'>
                                <input type="submit" value="Pesquisar" style={{backgroundColor: TenancyColor().backgroundColor}} className='cell-text-smile08'/>
                            </div>
                        </form>
                        <div className='w90 h20 column'>
                            <label className='w100 ma5 fs1p3em'>{config.pageName} Listados(a)</label>
                            {final}
                        </div>     
                    </div>
                </div>
            </div>
            
        </section>
      </>
    )
}





